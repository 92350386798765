import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faWeightHanging } from '@fortawesome/free-solid-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons';


export default function Modal({modalState, setModalState}) {

  const closeModal = () => {
    setModalState({ ...modalState, open: false });
  };

  //Choix des options d'affichage de la modal 
  const modalConfig = {
    sizeError: {
      icon: faWeightHanging,
      iconColor: 'text-[#ff2837]',
      infoTitre: "Le fichier n'est pas à la bonne taille",
      infoText: "Merci de joindre uniquement des fichiers avec une taille inférieure à 5 Mo."
    },
    formatError: {
      icon: faBan,
      iconColor: 'text-[#ff2837]',
      infoTitre: "Le fichier n'est pas au bon format",
      infoText: "Merci de joindre uniquement des fichiers au format PDF, JPG ou PNG."
    },
    success: {
      icon: faCircleCheck,
      iconColor: 'text-gold',
      infoTitre: "Votre demande a bien été prise en compte",
      infoText: "Merci de votre confiance. Nous vous ferons un retour dans les plus brefs délais."
    }
  };

// Définition des éléments qui dépendent de la condition
let stateKey;
if (modalState.sizeOK === false) {
  stateKey = 'sizeError';
} else if (modalState.formatOK === false) {
  stateKey = 'formatError';
} else {
  stateKey = 'success';
}

const { icon, iconColor, infoTitre, infoText } = modalConfig[stateKey];


  return (
    <Transition show={modalState.open}>
      <Dialog className="relative z-10" onClose={closeModal}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-black text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white p-10 sm:p-10 flex flex-col items-center">
                  <div className="sm:flex sm:items-start">
                    
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-center">
                    
                      <FontAwesomeIcon icon={icon} className={`${iconColor} h-12 w-12 mb-6`} />
                      <DialogTitle as="h3" className="text-base font-semibold leading-6 text-black">
                        {infoTitre}
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-black pb-8">
                          {infoText}
                        </p>
                      </div>
                    </div>
                    
                  </div>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center bg-gold px-6 py-3 text-sm font-semibold text-black shadow-sm hover:bg-goldHover sm:ml-3 sm:w-auto uppercase tracking-widest transition-all duration-500"
                    onClick={closeModal}
                  >
                    fermer
                  </button>
                </div>
                
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
