

import { useEffect } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from 'react-router-dom';


/* import frontImage from '../assets/home/interior-empty-warehouse_retsthiver.jpg';
import backImage from '../assets/home/interior-empty-warehouse_testhover.jpg';
import HeaderHover from './HeaderHover'; */

function HomeMainScreen( {mainBG, logoNoTxt, logoFacebook, logoLinkedin, logoX, logoYoutube} ) {

    useEffect(() => {
        AOS.init({ duration: 500, once: true, mirror: false });
    }, []);

    return (
        <div className="relative w-full h-full">
            {/* Image de Background */}
            <img src={mainBG} className="w-full h-full object-cover object-center" alt="Background" />
            {/* <HeaderHover frontImage={frontImage} backImage={backImage} /> */}
            {/* Masque noir */}
            <div className="absolute left-0 top-0 bg-black h-full w-full opacity-40 "></div>
            {/* Reseaux sociaux */}
            <div 
                className="absolute top-0 flex flex-col z-50"
                data-aos="fade">
                    <NavLink to="https://www.facebook.com/GroupTabatabai/">
                        <img src={logoFacebook} className="w-1/4 lg:w-1/3 m-4 mb-2 opacity-60 hover:opacity-100 cursor-pointer transition-all duration-300" alt="accces facebook" />
                    </NavLink>
                    <NavLink to="https://www.linkedin.com/company/tabatabai-group/">
                        <img src={logoLinkedin} className="w-1/4 lg:w-1/3 m-4 mb-2 mt-0 opacity-60 hover:opacity-100 cursor-pointer transition-opacity duration-300" alt="accces linkedin" />
                    </NavLink>
                    <NavLink to="https://twitter.com/TabatabaiGroup">
                        <img src={logoX} className="w-1/4 lg:w-1/3 m-4 mb-2 mt-0 opacity-60 hover:opacity-100 cursor-pointer transition-opacity duration-300" alt="accces twitter" />
                    </NavLink>
            </div>
            {/* Bloc intro */}
            <div className="absolute top-1/3 z-50 w-full m-auto">
                {/* Titre */}
                <h1 className="
                        text-white text-center uppercase text-3xl lg:text-6xl font-primary
                        transition-all duration-300"
                    data-aos="fade-down">
                    diagstructure
                </h1>
                {/* Bloc logo */}
                <div className="flex items-center justify-center ">
                    <hr className="
                        w-1/4 h-[2px] border-0 bg-white m-0 p-0"
                        data-aos="fade-right">
                    </hr>                    
                    <img 
                        src={logoNoTxt} 
                        className="
                            w-[50px] sm:w-[125px] md:w-[150px] mx-6 mt-6
                            transition-all duration-300" 
                        alt="logo"
                        data-aos="zoom-in"
                        data-aos-duration="1000" />
                    <hr className="
                        w-1/4 h-[2px] border-0 bg-white m-0 p-0"
                        data-aos="fade-left">
                    </hr>
                </div>
            </div>
            {/* Slogan */}
            <h2 className="
                uppercase absolute w-full bottom-[10%] m-auto 
                font-secondary text-white z-50 text-xs sm:text-xl
                transition-all duration-300"
                data-aos="fade-up">
                diagnostic structurel suite à sinistre
            </h2>
        </div>
    );
}

export default HomeMainScreen; 