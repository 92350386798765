import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'; 
import { NavLink } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { Collapse, initTWE } from "tw-elements";

/* Importation de toutes les images */
import imgBG from '../assets/DT2/DT2-side01-BG.jpg';
import MasonrySlider from '../components/masonrySlider';


function importAll(r) {
    return r.keys().filter((item) => item !== './DT2-side01-BG.jpg').map(r);
  }
const images = importAll(require.context('../assets/DT2', false, /\.(jpeg|jpg|JPG)$/));


function DT2() {

    useEffect(() => {
        AOS.init({ duration: 500, once: true, mirror: false });
        initTWE({ Collapse });
    }, []);

    return(

        <div className="min-h-screen" style={{
                backgroundImage: `url(${imgBG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}>
                {/* Bouton de rtour */}
                <NavLink to="/">
                    <FontAwesomeIcon icon={faChevronLeft} className='fixed top-12 z-50  left-16 text-gold text-4xl'/>
                </NavLink>

                {/* Contenu de la page */}
                <div className='flex flex-col sm:flex-row '>
                    {/* Premiere colone */}
                    <div className="w-full sm:w-1/3 h-full font-primary text-white text-center sm:text-right p-20 pt-36 sm:pt-20" data-aos="fade-right">
                        <h2 className="text-4xl sm:text-lg md:text-2xl lg:text-4xl xl:text-6xl 2xl:text-7xl uppercase mb-4 transition-all duration-300 font-primary">diagnostic</h2>
                        <h3 className="text-2xl sm:text-base md:text-xl  font-normal transition-all duration-300 font-secondary">Diagnostic Structure Sinistres majeurs</h3>
                    </div>
                    {/* Deuxieme colone */}
                    <div className='w-full sm:w-2/3 min-h-screen bg-opacity-50 backdrop-blur-md bg-white text-sm text-left p-20' data-aos="fade-left">
                        <h2 className='mb-10 -mt-6 font-primary font-bold text-9xl'>DT2</h2>
                        <h4 className='uppercase font-primary text-gold mb-6 text-2xl font-medium'>Domaine d'intervention</h4>
                        <p className='text-white font-bold font-secondary mb-4'>Types de sinistres concernés :</p>
                        <ul className='text-white list-disc pl-6 font-secondary leading-loose'>
                            <li>Ossatures en Béton Armé</li>
                            <li>Charpente métallique</li>
                            <li>Charpente Bois (lamellé-collé, traditionnel, fermettes industrialisées, pans de bois,…)</li>
                            <li>Béton Précontraint</li>
                        </ul>

                        <h4 className='uppercase font-primary text-gold mb-8 mt-10 text-2xl font-medium'>Détails de la mission</h4>

                        {/* Accordeon menu */}
                        <div id="accordeonParties">
                            
                            <div className="P1 mb-8">
                                <h2 className="mb-0" id="partie01">
                                <button
                                    className="group relative flex justify-between w-full items-center px-5 py-2 pt-4 pb-4 text-left text-sm text-white transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-black bg-opacity-50 rounded-full"
                                    type="button"
                                    data-twe-collapse-init
                                    data-twe-collapse-collapsed
                                    data-twe-target="#collapsePartie01"
                                    aria-expanded="false"
                                    aria-controls="collapsePartie01">
                                    Partie 1 : Inspection sur site – reconnaissance structurelle et/ou géotechnique
                                    <span
                                    className="-me-1 ms-auto h-5 w-5 ml-6 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0  group-data-[twe-collapse-collapsed]:ml-6 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6 ">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor">
                                        <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                    </span>
                                </button>
                                </h2>
                                <div
                                id="collapsePartie01"
                                className="!visible hidden"
                                data-twe-collapse-item
                                aria-labelledby="partie01">
                                <div className="px-5 py-4">
                                <ul className='text-white list-disc pl-6 text-sm font-secondary mb-10 leading-loose'>
                                    <li>Identification et repérage des dommages</li>
                                    <li>Inventaire des actions (charges permanentes et variables)</li>
                                    <li>Réalisation de relevés</li>
                                    <li>Description des ouvrages (constitution) et de leur fonctionnement (stabilité)</li>
                                    <li>Examen visuel, pour les parties accessibles et visibles de l’état de conservation des ouvrages (désordres, anomalies, impacts etc…)</li>
                                    <li>Constat des désordres dans la zone endommagée,</li>
                                    <li>Relevé de la géométrie des éléments,</li>
                                    <li>Examen des dommages visibles (déformation, rupture de liaison, fissures, pliage, cloquage, pourriture),</li>
                                    <li>Examen des organes de liaison (désordres visibles de type rupture, fissure, défaut géométrique),</li>
                                    <li>Examen des protections de type galvanisation ou peinture (présence de point de corrosion, de boursouflures et de cloquage),</li>
                                    <li>Pour chaque désordre ou anomalie, localisation sur les plans guides établis et constat avec reportage photographique associé (précisions sur la gravité et sur les éventuelles mesures provisoires confortatives à prendre),</li>
                                    <li>Mesures de déformation des éléments de structure par relevé laser ou géomètre (exemple : déplacement horizontal et vertical des pannes et portiques),</li>
                                    <li>Avis sur les mesures conservatoires existantes ou à prévoir.</li>
                                    <li>Avis technique sur les causes des désordres.</li>
                                    <li>Sondages</li>
                                    <li>Réalisation de sondages structurels (destructifs ou non)</li>
                                    <li>MÉtude de sol (en option)</li>
                                    
                                </ul>
                                </div>
                                </div>
                            </div>
                            <div className="P2 mb-8">
                                <h2 className="mb-0" id="partie02">
                                    <button
                                        className="group relative flex justify-between w-full items-center px-5 py-2 pt-4 pb-4 text-left text-sm text-white transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-black bg-opacity-50 rounded-full"
                                        type="button"
                                        data-twe-collapse-init
                                        data-twe-collapse-collapsed
                                        data-twe-target="#collapsePartie02"
                                        aria-expanded="false"
                                        aria-controls="collapsePartie02">
                                        Partie 2 – Calcul – Analyse de la capacité résiduelle des ouvrages endommagés
                                        <span
                                        className="-me-1 ms-auto h-5 w-5 ml-6 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0  group-data-[twe-collapse-collapsed]:ml-6 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6 ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor">
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                id="collapsePartie02"
                                className="!visible hidden"
                                data-twe-collapse-item
                                aria-labelledby="partie02">
                                    <div className="px-5 py-4">
                                        <ul className='text-white list-disc pl-6 text-sm font-secondary mb-10 leading-loose'>
                                            <li>Note d’hypothèses (définition des chargements),</li>
                                            <li>Modélisation des structures sur logiciel,</li>
                                            <li>Calcul des sollicitations et déplacements, </li>
                                            <li>Analyse des contraintes et déplacements,</li>
                                            <li>Avis technique sur le dimensionnement du bâtiment au regard des charges permanentes et variables à la date de sa construction,</li>
                                            <li>Avis technique sur l’adaptation des fondations sur la structure et/ou le sol d’assise si nécessaire.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="P3 mb-8">
                                <h2 className="mb-0" id="partie03">
                                    <button
                                        className="group relative flex justify-between w-full items-center px-5 py-2 pt-4 pb-4 text-left text-sm text-white transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-black bg-opacity-50 rounded-full"
                                        type="button"
                                        data-twe-collapse-init
                                        data-twe-collapse-collapsed
                                        data-twe-target="#collapsePartie03"
                                        aria-expanded="false"
                                        aria-controls="collapsePartie03">
                                        Partie 3 – Avis sur les travaux de confortation
                                        <span
                                        className="-me-1 ms-auto h-5 w-5 ml-6 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0  group-data-[twe-collapse-collapsed]:ml-6 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6 ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor">
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                id="collapsePartie03"
                                className="!visible hidden"
                                data-twe-collapse-item
                                aria-labelledby="partie02">
                                    <div className="px-5 py-4">
                                        <ul className='text-white list-disc pl-6 text-sm font-secondary mb-10 leading-loose'>
                                            <li>Descriptif général des zones à renforcer,</li>
                                            <li>Justification du projet,</li>
                                            <li>Établissement d’un rapport de synthèse,</li>
                                            <li>Prix sur devis</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="P4 mb-8">
                                <h2 className="mb-0" id="partie04">
                                    <button
                                        className="group relative flex justify-between w-full items-center px-5 py-2 pt-4 pb-4 text-left text-sm text-white transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none bg-black bg-opacity-50 rounded-full"
                                        type="button"
                                        data-twe-collapse-init
                                        data-twe-collapse-collapsed
                                        data-twe-target="#collapsePartie04"
                                        aria-expanded="false"
                                        aria-controls="collapsePartie04">
                                        Partie 4 – Les options
                                        <span
                                        className="-me-1 ms-auto h-5 w-5 ml-6 shrink-0 rotate-[-180deg] transition-transform duration-200 ease-in-out group-data-[twe-collapse-collapsed]:me-0  group-data-[twe-collapse-collapsed]:ml-6 group-data-[twe-collapse-collapsed]:rotate-0 motion-reduce:transition-none [&>svg]:h-6 [&>svg]:w-6 ">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor">
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                id="collapsePartie04"
                                className="!visible hidden"
                                data-twe-collapse-item
                                aria-labelledby="partie04">
                                    <div className="px-5 py-4">
                                        <ul className='text-white list-disc pl-6 text-sm font-secondary mb-10 leading-loose'>
                                            <li>Les sondages</li>
                                            <li>Maîtrise d’œuvre de conception,</li>
                                            <li>Chiffrage sommaire des travaux,</li>
                                            <li>DQE</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Affichage des images */}
                        <MasonrySlider customSlides={images} />
                        <MasonrySlider customSlides={images} />
                        
                        
                    </div>
                </div>
        </div>

    );
}

export default DT2;