import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'; 
import { NavLink } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

/* Importation de toutes les images */
import imgBG from '../assets/DT1/DT1-side01-BG.jpg';
import MasonrySlider from '../components/masonrySlider';

function importAll(r) {
    return r.keys().filter((item) => item !== './DT1-side01-BG.jpg').map(r);
  }
const images = importAll(require.context('../assets/DT1', false, /\.(jpeg|jpg|JPG)$/));


function DT1() {

    useEffect(() => {
        AOS.init({ duration: 500, once: true, mirror: false });
    }, []);
    

    return(

        <div className="min-h-screen" style={{
                backgroundImage: `url(${imgBG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}>
                {/* Bouton de rtour */}
                <NavLink to="/">
                    <FontAwesomeIcon icon={faChevronLeft} className='fixed top-12 z-50  left-16 text-gold text-4xl'/>
                </NavLink>

                {/* Contenu de la page */}
                <div className='flex flex-col sm:flex-row '>
                    {/* Premiere colone */}
                    <div className="w-full sm:w-1/3 h-full font-primary text-white text-center sm:text-right p-20 pt-36 sm:pt-20" data-aos="fade-right">
                        <h2 className="text-4xl sm:text-lg md:text-2xl lg:text-4xl xl:text-6xl 2xl:text-7xl uppercase mb-4 transition-all duration-300 font-primary">diagnostic</h2>
                        <h3 className="text-2xl sm:text-base md:text-xl  font-normal transition-all duration-300 font-secondary">Diagnostic structure sinistres courants</h3>
                    </div>
                    {/* Deuxieme colone */}
                    <div className='w-full sm:w-2/3 min-h-screen bg-opacity-50 backdrop-blur-sm bg-white text-sm text-left p-20' data-aos="fade-left">
                        <h2 className='mb-10 -mt-6 font-primary font-bold text-9xl'>DT1</h2>
                        <h4 className='uppercase font-primary text-gold mb-4 text-xl font-medium'>Domaine d'intervention</h4>
                        <ul className='text-white list-disc pl-6 font-secondary'>
                            <li>Sinistre Incendie, explosion, affaissement, effondrement. Sinistres Catastrophes naturelles, climatiques,…</li>
                            <li>Sinistres dont les dommages visibles semblent ne pas avoir remis en cause la solidité de la structure mais pour lesquels l’expert souhaite confirmer son appréciation.</li>
                        </ul>
                        <p className='text-white mt-4 font-secondary'><span className='font-bold'>Types de sinistres concernés :</span> Sinistres courants localisés à des zones ou ouvrages de faible étendue (plancher d’appartement, ossatures de parking,…)</p>

                        <h4 className='uppercase font-primary text-gold mb-4 mt-12 text-xl font-medium'>Détails de la mission</h4>
                        <ul className='text-white list-disc pl-6 font-secondary'>
                            <li>Visite sur place</li>
                            <li>Examen visuel des désordres</li>
                            <li>Réalisation de sondages de reconnaissances destructifs légers</li>
                            <li>Réalisation d’essais au scléromètre</li>
                            <li>Relevé pour l’établissement d’un plan et implantation des sondages</li>
                            <li>Relevé photographique</li>
                            <li>Établissement d’un rapport de synthèse comprenant un avis sur la pérennité de la structure et un avis sur d’éventuelles mesures d’urgence, et le cas échéant une orientation sur les solutions de réparation éventuellement nécessaires indiquées sur le plan de repérage, et relevé photographique localisé sur plan</li>
                        </ul>
                        <p className='text-white mt-4 font-secondary'><span className='font-bold'>Types de sinistres concernés :</span> Sinistres courants localisés à des zones ou ouvrages de faible étendue (plancher d’appartement, ossatures de parking,…)</p>

                        <p className='text-white font-bold mt-12 font-secondary'>En option :</p>
                        <ul className='text-white list-disc pl-6 font-secondary'>
                            <li>Prélèvements et carottages pour la réalisation d’essais au laboratoire</li>
                            <li>Une auscultation ultrasonique de la structure</li>
                        </ul>

                        {/* Affichage des images */}
                        <MasonrySlider customSlides={images} />

                    </div>
                </div>
        </div>

    );
}

export default DT1;