import React, { useState, useEffect } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

function MasonrySlider({ customSlides }) {

    const [loadedImages, setLoadedImages] = useState(0);
    const [allImagesLoaded, setAllImagesLoaded] = useState(false);

    useEffect(() => {
        if (customSlides.length === 0) {
            setAllImagesLoaded(true);
        } else if (loadedImages === customSlides.length) {
            setAllImagesLoaded(true);
        }
    }, [loadedImages, customSlides.length]);

    const handleImageLoad = () => {
        setLoadedImages(prevCount => prevCount + 1);
    };

    const handleImageError = () => {
        setLoadedImages(prevCount => prevCount + 1);
    };

    return (
        <>
            {!allImagesLoaded 
                ? 
                <div className="loader">Loading...</div> 
                :
                <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                >
                    <Masonry>
                        {customSlides.map((image, i) => (
                            <img
                                key={i}
                                src={image}
                                style={{width: "100%", display: "block", padding: "10px"}}
                                alt=""
                                onLoad={handleImageLoad}
                                onError={handleImageError}
                            />
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            }  
        </>
    )
}

export default MasonrySlider;
