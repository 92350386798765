import HomeMainScreen from "../components/HomeMainScreen";
import HomeSideScreen from "../components/HomeSideScreen";
//Tous les Backgrounds
import mainBG from '../assets/home/home-main-BG.jpg';
import sideBG01 from '../assets/home/home-side01-BG.jpg';
import sideBG02 from '../assets/home/home-side02-BG.jpg';
import sideBG03 from '../assets/home/home-side03-BG.jpg';

import logoNoTxt from '../assets/home/home-logo-no-txt.png';
import logoFacebook from '../assets/home/home-RS-facebook.png';
import logoLinkedin from '../assets/home/home-RS-linkedin.png';
import logoX from '../assets/home/home-RS-x.png';
import logoYoutube from '../assets/home/home-RS-youtube.png';



function Home() {
    return (
        
        <div className="bg-black w-screen h-screen text-white flex flex-col sm:flex-row p-14">
            <div className="w-full h-auto mb-14 sm:mb-0 pr-0 sm:pr-14 md:w-3/5 md:pr-14">
                <HomeMainScreen mainBG={mainBG} logoNoTxt={logoNoTxt} logoFacebook={logoFacebook} logoLinkedin={logoLinkedin} logoX={logoX} logoYoutube={logoYoutube} />
            </div>
            <div className="w-full h-auto mt-0 pr-0 md:mt-0 md:w-2/5">
                <HomeSideScreen sideBG01={sideBG01} sideBG02={sideBG02} sideBG03={sideBG03}/>
            </div>
        </div>
    );
    
}
export default Home;