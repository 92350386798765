import { useEffect, useState } from 'react';
import imgIllu from '../assets/contact/illustration_house.jpg';
import imgCarteAgence from '../assets/contact/carte_agences.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

import { useForm } from "react-hook-form";
import DOMPurify from 'dompurify';

import { sendToCRM } from '../components/CRMaccess';
import Modal from '../components/Modal';


function Contact() {

    useEffect(() => {
        AOS.init({ once: true, mirror: false });
    }, []);

    const { register, handleSubmit, reset,  formState: { errors } } = useForm();
    const [fileName, setFileName] = useState('Choisissez votre fichier...');
    const [fileContent, setFileContent] = useState('');
    const [modalState, setModalState] = useState({ open: false, formatOK: true, sizeOK: true });
    const [activeIndex, setActiveIndex] = useState(0);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        e.target.value = '';

        if (file) {
            
            // Taille maximale autorisée (5MB)
            const maxSize = 5 * 1024 * 1024; 
            // Vérif du type de fichier
            const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
            // Réinitialisez les états avant les vérifications
            let isFormatOk = validTypes.includes(file.type);
            let isSizeOk = file.size <= maxSize;

            // Le fichier est valide
            if (isFormatOk && isSizeOk) {
                setFileName(file.name);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setFileContent(reader.result.toString());
                };
                reader.readAsDataURL(file);
            } else {
                // Reset de l'input file
                setFileContent('');
                setFileName('Choisissez votre fichier...');
                // Définissez l'état de la modal en fonction de l'erreur
                setModalState({
                    ...modalState,
                    open: true,
                    formatOK: isFormatOk,
                    sizeOK: isSizeOk
                });
            }
        }
    }

    const listAgences = [
        {
            "ville":"ECQUEVILLY",
            "adresse":"ZAC du Petit Parc - 12 rue de la petite Garenne 78920 Ecquevilly",
            "telephone": "01 34 75 21 72",
        },
        {
            "ville":"GUERANDE",
            "adresse":"Château de Treveday Bat A - Ferme du Château 44350 Guérande",
            "telephone": "02 40 23 02 99",
        },
        {
            "ville":"LAVAUR",
            "adresse":"359 rue Léonard de Vinci 81500 Lavaur",
            "telephone": "05 63 83 17 25",
        },
        {
            "ville":"LIMOGES",
            "adresse":"18 rue de Tourcoing 87100 Limoges",
        },
        {
            "ville":"MEYREUIL",
            "adresse":"530 chemin du Pontet 13590 Meyreuil",
            "telephone": "04 42 25 99 00",
        },
        {
            "ville":"NIMES",
            "adresse":"3218 Avenue Kennedy 30900 Nîmes",
        },
        {
            "ville":"OLIVET",
            "adresse":"600 Rue de la Juine  45160 Olivet",
        },
        {
            "ville":"PARIS",
            "adresse":"32 Rue de la Boetie 75008 Paris",
        },
        {
            "ville":"SAULNY",
            "adresse":"Vaussechamps 57140 Saulny",
            "telephone": "03 52 44 01 76",
        },
        {
            "ville":"VAULX EN VELIN",
            "adresse":"Avenue Salvador Allende - Immeuble STARGATE 69120 Vaulx en Velin",
            "telephone": "04 11 62 01 20",
        },
        {
            "ville":"WASQUEHAL",
            "adresse":"Avenue de la Marne - La Vigie 59290 Wasquehal",
            "telephone": "03 20 80 63 25",
        },
    ]

    // Etat du formulaire pour gérer le bouton
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Protège contre l'injection de contenu HTML non désiré
    function encodeHtml(input) {
        const element = document.createElement('div');
        if (input) {
            element.innerText = input;
            return element.innerHTML;
        }
        return input;
    }
    // Élimine les éléments et attributs potentiellement dangereux qui pourraient contourner l'encodage HTML
    function sanitizeInput(input) {
        return DOMPurify.sanitize(encodeHtml(input)); 
    }

    const onSubmit = async (data, e) => {

        //Controle des données saisies par l'utilisateur
        data.prenom = sanitizeInput(data.prenom);
        data.nom = sanitizeInput(data.nom);
        data.email = sanitizeInput(data.email);
        data.phone = sanitizeInput(data.phone);
        data.societe = sanitizeInput(data.societe);
        data.codepostal = sanitizeInput(data.codepostal);
        data.message = sanitizeInput(data.message);

        //Données préremplies
        data.ville = "Paris";
        data.demande = "Demande de devis";
        data.vous_etes = "Client";
        data.company_id = "8";
        data.entite = "Diagstructure";
        data.sujet = "Demande de devis";
        data.idEmailTemplate = "d-2c5a90a91642483aa1b8fe552cbd9634";
        data.email_expediteur = "webmaster@diagstructure.com";
        data.email_destinataires = [
            "c.chouquet@determinant.fr",
            "t.vigneswaran@determinant.fr",
            "contact@diastruscture.fr"
        ]

        if (fileContent) {
            data.fichier = { 
                base64Content: fileContent,
                fileName: fileName
            };
        }else{
            data.fichier =  false;
        }

        if (data.autres) {
            // Il s'agit probablement d'un bot, donc ignorez la soumission
            return;
        }
        setIsSubmitting(true);
        sendToCRM(data, setIsSubmitting, reset, modalState, setModalState, setFileName, setFileContent);
        
    };



    return (
        <>
        <div className="bg-black w-screen h-screen text-white flex flex-col sm:flex-row p-14">
            {/* Bouton de retour */}
            <NavLink to="/">
                <FontAwesomeIcon icon={faChevronLeft} className='fixed top-12 z-50 sm:top-auto left-16 text-gold text-4xl '/>
            </NavLink>
            {/* Colonne gauche */}
            <div className="w-full h-auto mt-14 pr-0 hidden sm:block sm:pr-14 md:pr-14">
                <div className="relative w-full h-full" data-aos="fade-right">
                    {/* Image de Background */}
                        <div className={`w-full h-full transition-all duration-300 absolute ${activeIndex === 0 ? 'opacity-100' : 'opacity-0'}`}>
                            <img src={imgIllu} className="w-full h-full object-cover object-center" alt="Background Illustration"/>
                            {/* Masque noir */}
                            <div className="absolute left-0 top-0 bg-black h-full w-full opacity-40"></div>
                        </div>  
                        <div className={`w-full h-full transition-all duration-300 absolute ${activeIndex !== 0 ? 'opacity-100' : 'opacity-0'}`}>
                            <img src={imgCarteAgence} className="w-full h-full object-contain object-center" alt="Background Carte des agences"/>
                        </div>                    
                </div>            
            </div>
            {/* Colonne droite */}
            <div className="w-full h-auto p-10 flex justify-center items-center flex-col" data-aos="fade-left">
                <div className='w-full top-0 absolute mt-14'>
                    {/* Titre */}
                    <div className="relative m-auto w-full mb-24 sm:top-0 sm:right-0 sm:mb-6 md:mb-20 cursor-pointer">
                        <div className='block w-1/2 float-left' onClick={() => setActiveIndex(0)}>
                            <h1 className='text-white uppercase text-2xl sm:text-2xl lg:text-3xl xl:text-2xl sm:tracking-[0.2em]'>Contact</h1>
                        </div>
                        <div className='block w-1/2 float-left' onClick={() => setActiveIndex(1)}>
                            <h1 className='text-white uppercase text-2xl sm:text-2xl lg:text-3xl xl:text-2xl sm:tracking-[0.2em]'>Agences</h1>
                        </div>
                        <div className={`absolute left-0 top-10 xl:top-12 h-1 bg-gold transition-transform duration-300 ${activeIndex === 1 ? 'translate-x-full' : ''} w-1/2`}></div>
                    </div>
                    {/* Formulaire */}
                    <div className='relative w-full'>
                        <form onSubmit={handleSubmit(onSubmit)} className={`absolute text-left transition-all duration-300 w-full ${activeIndex === 0 ? 'opacity-100' : 'opacity-0'}`}>
                            <div className="mt-8 3xl:mt-20 grid grid-cols-2 gap-x-20 ">
                                {/* Champ Prénom */}
                                <div className="relative w-full h-11 flex flex-col">
                                    <input 
                                        name="prenom"
                                        autoComplete='off' 
                                        className="peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200 w-full" 
                                        placeholder=" "
                                        {...register("prenom", { required: true })}
                                    />
                                    <label 
                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-white leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-200 peer-focus:text-tertiary-400 after:border-gray-200 peer-focus:after:border-white">Prénom 
                                    </label>
                                    {errors.prenom && <span className="text-[#ff2837] text-sm">Le champ prénom est requis</span>}
                                </div>
                                {/* Champ Nom  */}
                                <div className="relative w-full h-11 flex flex-col">
                                    <input 
                                        name="nom" 
                                        autoComplete='off' 
                                        className="peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200 w-full" 
                                        placeholder=" "
                                        {...register("nom", { required: true })}
                                    />
                                    <label 
                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-white leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-200 peer-focus:text-tertiary-400 after:border-gray-200 peer-focus:after:border-white">Nom 
                                    </label>
                                    {errors.nom && <span className="text-[#ff2837] text-sm">Le champ nom est requis</span>}
                                </div>
                            </div>

                            <div className="mt-8 grid grid-cols-2 gap-x-20">
                                {/* Champ Adresse email  */}
                                <div className="relative w-full  h-11 flex flex-col">
                                    <input 
                                        name="email" 
                                        autoComplete='off' 
                                        className="w-full peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200" 
                                        placeholder=" "
                                        {...register("email", { 
                                            required: "Le champ mail est requis",
                                            pattern: {
                                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                message: "Le champ email doit être une adresse e-mail valide"
                                            }
                                        })}
                                    />
                                    <label 
                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-white leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-200 peer-focus:text-tertiary-400 after:border-gray-200 peer-focus:after:border-white">Adresse mail 
                                    </label>
                                    {errors.email && <span className="text-[#ff2837] text-sm">Le champ mail est requis et doit être au bon format</span>}
                                </div>
                                {/* Champ Téléphone  */}
                                <div className="relative w-full  h-11 flex flex-col">
                                    <input 
                                        name="phone" 
                                        autoComplete='off' 
                                        className="w-full peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200" 
                                        placeholder=" "
                                        {...register("phone", { required: true })}
                                    />
                                    <label 
                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-white leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-200 peer-focus:text-tertiary-400 after:border-gray-200 peer-focus:after:border-white">Téléphone 
                                    </label>
                                    {errors.phone && <span className="text-[#ff2837] text-sm">Le champ téléphone est requis</span>}
                                </div>
                            </div>

                            <div className="mt-8 grid grid-cols-2 gap-x-20">
                                {/* Champ Votre société  */}
                                <div className="relative w-full  h-11 flex flex-col">
                                    <input 
                                        name="societe" 
                                        autoComplete='off' 
                                        className="w-full peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200" 
                                        placeholder=" "
                                        {...register("societe", { required: true })}
                                    />
                                    <label 
                                        className="flex w-full  h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-white leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-tertiary-200 peer-focus:text-tertiary-400 after:border-tertiary-200 peer-focus:after:border-white">Votre société 
                                    </label>
                                    {errors.societe && <span className="text-[#ff2837] text-sm">Le champ société est requis</span>}
                                </div>
                                {/* Champ Code postal  */}
                                <div className="relative w-full  h-11 flex flex-col">
                                    <input 
                                        name="codepostal" 
                                        autoComplete='off' 
                                        className="w-full peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200" 
                                        placeholder=" "
                                        {...register("codepostal", { required: true })}
                                    />
                                    <label 
                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-white leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-200 peer-focus:text-tertiary-400 after:border-gray-200 peer-focus:after:border-white">Code postal 
                                    </label>
                                    {errors.codepostal && <span className="text-[#ff2837] text-sm">Le champ code postale est requis</span>}
                                </div>
                            </div>

                            <div className="mt-8 grid grid-cols-1 mb-4 3xl:mb-14">
                                
                                {/* Champ Message  */}
                                <div className="relative w-full  flex flex-col">
                                    <textarea 
                                        name="message" 
                                        autoComplete='off' 
                                        className="peer w-full h-full min-h-[100px] bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:resize-none transition-all border-0 placeholder-shown:border-tertiary-200 focus:ring-0 text-sm border-white focus:border-white !resize-none mt-6 p-0 border-b " 
                                        placeholder=" "
                                        {...register("message", { required: true })}
                                    >
                                    </textarea>
                                    <label 
                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-white leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-200 peer-focus:text-tertiary-400 after:border-gray-200 peer-focus:after:border-white">Message
                                    </label>
                                </div>
                                {errors.message && <span className="text-[#ff2837] text-sm">Il faut renseigner votre message</span>}

                                {/* Autres */}
                                <div className="relative w-full  h-11 flex flex-col">
                                    <input 
                                        name="autres" 
                                        autoComplete='off' 
                                        className="hidden w-full peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200" 
                                        placeholder=" "
                                        {...register("autres")}
                                    />
                                </div>

                            </div>

                            <div className='flex flex-row pb-4 items-center'>
                                <FontAwesomeIcon icon={faCircleExclamation} className='text-gold h-6 w-6 mr-4'/>  
                                <p className='text-sm'>Merci de joindre uniquement des fichiers au format PDF, JPG ou PNG et de taille inferieur à 5 Mo</p>
                            </div>
                            <div className="border-tertiary-200 border-2 flex flex-col aligns-center items-center p-4 3xl:p-10 mb-4 text-center">
                                {/* Champ joindre un fichier */}
                                <input 
                                    disabled={isSubmitting}
                                    type="file" 
                                    name="fichier" 
                                    id="fichier" 
                                    className="w-[0.1] h-[0.1] opacity-0 overflow-hidden absolute -z-index-10" 
                                    {...register("file")}
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="fichier" className="text-tertiary-400 max-w-[80%] text-md 3xl:text-xl text-ellipsis inline-blocks overflow-hidden whitespace-nowrap cursor-pointer">
                                    <svg version="1.1" id="Calque_1"viewBox="0 0 38 39" className="h-10 w-10 m-auto">
                                        <path className="fill-none stroke-[#EEC32D] stroke-2" d="M3.8,20.2V32c0,1.9,1.5,3.4,3.4,3.4h23.6c1.9,0,3.4-1.5,3.4-3.4V20.2"/>
                                        <polyline className="fill-none stroke-[#EEC32D] stroke-2" points="12.1,10.8 19,4 25.9,10.8 "/>
                                        <line className="fill-none stroke-[#EEC32D] stroke-2" x1="19" y1="26.9" x2="19" y2="7.6"/>
                                    </svg>
                                    <span>{fileName}</span>
                                </label>
                            </div>

                            <button disabled={isSubmitting} type="submit" className="w-full uppercase tracking-widest m-auto block py-5 px-5 me-2 text-black bg-white transition-all duration-500 hover:bg-gray-100 focus:z-10 focus:ring-4 focus:outline-none dark:bg-gray-800  dark:hover:bg-gray-700 items-center">
                                {isSubmitting ? (
                                    <>
                                        <svg aria-hidden="true" role="status" className="inline fill-gold w-8 h-8 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101"  xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"/>
                                        </svg>
                                        Envoi en cours ...
                                    </>
                                ) : (
                                    "Envoyer"
                                )}
                            </button>

                        
                        </form>
                        {/* Adresses des agences */}
                        <div className={`mt-8 3xl:mt-20 grid grid-cols-2 gap-x-20 ${activeIndex !== 0 ? 'opacity-100' : 'opacity-0'}`}>
                            {listAgences.map((agence, index) => (
                                <div key={index} className="w-full h-auto flex flex-col text-left mb-8">
                                    <h2 className="text-white text-lg font-semibold uppercase">{agence.ville}</h2>
                                    <p className="text-white text-sm">{agence.adresse}</p>
                                    {agence.telephone && <p className="text-white text-sm">{agence.telephone}</p>}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <Modal modalState={modalState} setModalState={setModalState}/>
        </>
    );
    
}
export default Contact;