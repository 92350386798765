import React from 'react';
import { BrowserRouter, Routes , Route } from 'react-router-dom';

/* import frontImage from '../src/assets/home/interior-empty-warehouse_retsthiver.jpg';
import backImage from '../src/assets/home/interior-empty-warehouse_testhover.jpg';
import HeaderHover from './components/HeaderHover'; */
import './css/App.css';
import './css/output.css';

import Home from './pages/home';
import DT1 from './pages/DT1';
import DT2 from './pages/DT2';
import Contact from './pages/contact';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/Diagnostic_structure_sinistres_courants_DT1' element={<DT1 />} />
          <Route path='/Diagnostic_Structure_Sinistres_majeurs_DT2' element={<DT2 />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </BrowserRouter>
      {/* <HeaderHover frontImage={frontImage} backImage={backImage} /> */}
    </div>
  );
}

export default App;
