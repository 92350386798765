import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';


function HomeSideScreen( {sideBG01, sideBG02, sideBG03} ) {

    useEffect(() => {
        AOS.init({ once: true, mirror: false });
    }, []);

    return (
        <>  
            {/* BLOC GENERAL CHOIX INTERVENTION */}
            <div className="relative w-full h-full flex flex-col items-center justify-between">
                {/* Bloc choix intervention */}
                <NavLink to="/Diagnostic_structure_sinistres_courants_DT1" className="relative w-full h-[30%] mb-14 sm:mb-0 cursor-pointer group" data-aos="fade-down" data-aos-duration="750">
                    {/* Image de fond */}
                    <img 
                        src={sideBG01} 
                        className="
                            w-full h-full object-cover object-center 
                            grayscale sm:grayscale-0 group-hover:grayscale  
                            transition-all duration-300" 
                        alt="lien vers DT1" />
                    {/* Masque noir */}
                    <div className="
                        absolute left-0 top-0 bg-black h-full w-full 
                        opacity-50"></div>
                    {/* Masque blur*/}
                    <div className="absolute inset-0 transition-opacity duration-300 ease-in-out bg-transparent group-hover:opacity-100 group-hover:backdrop-blur-sm"></div>
                    {/* Bloc au srvol de l'image */}
                    <div className="
                        absolute left-0 top-0 w-full h-full
                        flex items-center justify-center flex-col 
                        text-white font-bold">
                        {/* Titre */}
                        <h2 
                            className="
                            uppercase 
                            opacity-100 sm:opacity-0 group-hover:opacity-100
                            translate-y-0  sm:-translate-y-7 group-hover:translate-y-0
                            font-primary text-sm xl:text-xl 2xl:text-2xl
                            transition-all duration-300">
                            diagnostic DT1
                        </h2>
                        {/* Separateur */}
                        <hr 
                            className="
                            w-1/6 h-[2px] 
                            border-0 bg-white my-4 
                            opacity-100 sm:opacity-0 group-hover:opacity-100 
                            transition-all duration-300">                        
                        </hr>
                        {/* Sous-Titre */}
                        <h3 className="
                            opacity-100 sm:opacity-0 group-hover:opacity-100 
                            translate-y-0 sm:translate-y-7 group-hover:translate-y-0 
                            font-secondary font-normal text-sm xl:text-xl 2xl:text-2xl  
                            transition-all duration-300">
                            Diagnostic Structure Sinistres courants
                        </h3>
                        {/* Cadre */}
                        <div className="
                            absolute h-full w-full
                            opacity-30 sm:opacity-0 group-hover:opacity-30
                            rotate-3 sm:rotate-0 group-hover:rotate-3
                            border-2 border-white 
                            transform-all duration-300">    
                        </div>
                    </div>
                </NavLink>
                {/* Bloc choix intervention */}
                <NavLink to="/Diagnostic_Structure_Sinistres_majeurs_DT2" className="relative w-full h-[30%] mb-14 sm:mb-0 cursor-pointer group" data-aos="fade-down" data-aos-duration="500">
                    {/* Image de fond */}
                    <img 
                        src={sideBG02} 
                        className="
                            w-full h-full object-cover object-center 
                            grayscale sm:grayscale-0 group-hover:grayscale  
                            transition-all duration-300" 
                        alt="lien vers DT2" />
                    {/* Masque noir */}
                    <div className="
                        absolute left-0 top-0 bg-black h-full w-full 
                        opacity-50"></div>
                    {/* Masque blur*/}
                    <div className="absolute inset-0 transition-opacity duration-300 ease-in-out bg-transparent group-hover:opacity-100 group-hover:backdrop-blur-sm"></div>
                    {/* Bloc au srvol de l'image */}
                    <div className="
                        absolute left-0 top-0 w-full h-full
                        flex items-center justify-center flex-col 
                        text-white font-bold">
                        {/* Titre */}
                        <h2 
                            className="
                            uppercase 
                            opacity-100 sm:opacity-0 group-hover:opacity-100
                            translate-y-0  sm:-translate-y-7 group-hover:translate-y-0
                            font-primary text-sm xl:text-xl 2xl:text-2xl
                            transition-all duration-300">
                            diagnostic DT2
                        </h2>
                        {/* Separateur */}
                        <hr 
                            className="
                            w-1/6 h-[2px] 
                            border-0 bg-white my-4 
                            opacity-100 sm:opacity-0 group-hover:opacity-100 
                            transition-all duration-300">                        
                        </hr>
                        {/* Sous-Titre */}
                        <h3 className="
                            opacity-100 sm:opacity-0 group-hover:opacity-100 
                            translate-y-0 sm:translate-y-7 group-hover:translate-y-0 
                            font-secondary font-normal text-sm xl:text-xl 2xl:text-2xl  
                            transition-all duration-300">
                            Diagnostic Structure Sinistres majeurs
                        </h3>
                        {/* Cadre */}
                        <div className="
                            absolute h-full w-full
                            opacity-30 sm:opacity-0 group-hover:opacity-30
                            rotate-3 sm:rotate-0 group-hover:rotate-3
                            border-2 border-white 
                            transform-all duration-300">    
                        </div>
                    </div>
                </NavLink>
                {/* Bloc choix intervention */}
                <NavLink to="/contact" className="relative w-full h-[30%] mb-14 sm:mb-0 cursor-pointer group" data-aos="fade-down" data-aos-duration="250">
                    {/* Image de fond */}
                    <img 
                        src={sideBG03} 
                        className="
                            w-full h-full object-cover object-center 
                            grayscale sm:grayscale-0 group-hover:grayscale  
                            transition-all duration-300" 
                        alt="lien vers contact" />
                    {/* Masque noir */}
                    <div className="
                        absolute left-0 top-0 bg-black h-full w-full 
                        opacity-50"></div>
                    {/* Masque blur*/}
                    <div className="absolute inset-0 transition-opacity duration-300 ease-in-out bg-transparent group-hover:opacity-100 group-hover:backdrop-blur-sm"></div>
                    {/* Bloc au srvol de l'image */}
                    <div className="
                        absolute left-0 top-0 w-full h-full
                        flex items-center justify-center flex-col 
                        text-white font-bold">
                        {/* Titre */}
                        <h2 
                            className="
                            uppercase 
                            opacity-100 sm:opacity-0 group-hover:opacity-100
                            translate-y-0  sm:-translate-y-7 group-hover:translate-y-0
                            font-primary text-sm xl:text-xl 2xl:text-2xl
                            transition-all duration-300">
                            contact
                        </h2>
                        {/* Separateur */}
                        <hr 
                            className="
                            w-1/6 h-[2px] 
                            border-0 bg-white my-4 
                            opacity-100 sm:opacity-0 group-hover:opacity-100 
                            transition-all duration-300">                        
                        </hr>
                        {/* Sous-Titre */}
                        <h3 className="
                            opacity-100 sm:opacity-0 group-hover:opacity-100 
                            translate-y-0 sm:translate-y-7 group-hover:translate-y-0 
                            font-secondary font-normal text-sm xl:text-xl 2xl:text-2xl  
                            transition-all duration-300">
                            Intervention France entière
                        </h3>
                        {/* Cadre */}
                        <div className="
                            absolute h-full w-full
                            opacity-30 sm:opacity-0 group-hover:opacity-30
                            rotate-3 sm:rotate-0 group-hover:rotate-3
                            border-2 border-white 
                            transform-all duration-300">    
                        </div>
                    </div>
                </NavLink>
            </div>
        </>
    );
}

export default HomeSideScreen; 