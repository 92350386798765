import axios from "axios";

function sendToCRM(data, setIsSubmitting, reset ,modalState, setModalState, setFileName, setFileContent) {

  // Création de l'objet FormData pour envoyer les données comme formulaire
  let formData = new FormData();
  formData.append('data', JSON.stringify(data));
  
  // Enregistrement de la demande dans le CRM et envoi du mail
  axios.post('https://www.ai6.fr/crm_website_links/create_crm', formData )
  .then(response => {

    if (response.data.response === "ok") {
      setIsSubmitting(false);
      setModalState({ ...modalState, open: true, formatOK: true, sizeOK: true });
      reset();
      setFileContent('');
      setFileName('Choisissez votre fichier...');
    }

  })
  .catch(error => {
    console.error('Error:', error);
  });
}
        
export default function CRMaccess({ onSubmit }) {
  return (
      <> </>
  );
}

export { sendToCRM };
